import * as _protoList2 from "proto-list";

var _protoList = "default" in _protoList2 ? _protoList2.default : _protoList2;

import * as _path2 from "path";

var _path = "default" in _path2 ? _path2.default : _path2;

import * as _fs2 from "fs";

var _fs = "default" in _fs2 ? _fs2.default : _fs2;

import * as _ini2 from "ini";

var _ini = "default" in _ini2 ? _ini2.default : _ini2;

import * as _events2 from "events";

var _events = "default" in _events2 ? _events2.default : _events2;

import * as _url2 from "url";

var _url = "default" in _url2 ? _url2.default : _url2;

import * as _http2 from "http";

var _http = "default" in _http2 ? _http2.default : _http2;

import _process from "process";
import _buffer from "buffer";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var module = {
  exports: exports
};
var Buffer = _buffer.Buffer;
var process = _process;
var ProtoList = _protoList,
    path = _path,
    fs = _fs,
    ini = _ini,
    EE = _events.EventEmitter,
    url = _url,
    http = _http;

var exports = module.exports = function () {
  var args = [].slice.call(arguments),
      conf = new ConfigChain();

  while (args.length) {
    var a = args.shift();
    if (a) conf.push("string" === typeof a ? json(a) : a);
  }

  return conf;
}; //recursively find a file...


var find = exports.find = function () {
  var rel = path.join.apply(null, [].slice.call(arguments));

  function find(start, rel) {
    var file = path.join(start, rel);

    try {
      fs.statSync(file);
      return file;
    } catch (err) {
      if (path.dirname(start) !== start) // root
        return find(path.dirname(start), rel);
    }
  }

  return find(new URL(import.meta.url.slice(0, import.meta.url.lastIndexOf("/"))).pathname, rel);
};

var parse = exports.parse = function (content, file, type) {
  content = "" + content; // if we don't know what it is, try json and fall back to ini
  // if we know what it is, then it must be that.

  if (!type) {
    try {
      return JSON.parse(content);
    } catch (er) {
      return ini.parse(content);
    }
  } else if (type === "json") {
    if ((this || _global).emit) {
      try {
        return JSON.parse(content);
      } catch (er) {
        this.emit("error", er);
      }
    } else {
      return JSON.parse(content);
    }
  } else {
    return ini.parse(content);
  }
};

var json = exports.json = function () {
  var args = [].slice.call(arguments).filter(function (arg) {
    return arg != null;
  });
  var file = path.join.apply(null, args);
  var content;

  try {
    content = fs.readFileSync(file, "utf-8");
  } catch (err) {
    return;
  }

  return parse(content, file, "json");
};

var env = exports.env = function (prefix, env) {
  env = env || process.env;
  var obj = {};
  var l = prefix.length;

  for (var k in env) {
    if (k.indexOf(prefix) === 0) obj[k.substring(l)] = env[k];
  }

  return obj;
};

exports.ConfigChain = ConfigChain;

function ConfigChain() {
  EE.apply(this || _global);
  ProtoList.apply(this || _global, arguments);
  (this || _global)._awaiting = 0;
  (this || _global)._saving = 0;
  (this || _global).sources = {};
} // multi-inheritance-ish


var extras = {
  constructor: {
    value: ConfigChain
  }
};
Object.keys(EE.prototype).forEach(function (k) {
  extras[k] = Object.getOwnPropertyDescriptor(EE.prototype, k);
});
ConfigChain.prototype = Object.create(ProtoList.prototype, extras);

ConfigChain.prototype.del = function (key, where) {
  // if not specified where, then delete from the whole chain, scorched
  // earth style
  if (where) {
    var target = (this || _global).sources[where];
    target = target && target.data;

    if (!target) {
      return this.emit("error", new Error("not found " + where));
    }

    delete target[key];
  } else {
    for (var i = 0, l = (this || _global).list.length; i < l; i++) {
      delete (this || _global).list[i][key];
    }
  }

  return this || _global;
};

ConfigChain.prototype.set = function (key, value, where) {
  var target;

  if (where) {
    target = (this || _global).sources[where];
    target = target && target.data;

    if (!target) {
      return this.emit("error", new Error("not found " + where));
    }
  } else {
    target = (this || _global).list[0];

    if (!target) {
      return this.emit("error", new Error("cannot set, no confs!"));
    }
  }

  target[key] = value;
  return this || _global;
};

ConfigChain.prototype.get = function (key, where) {
  if (where) {
    where = (this || _global).sources[where];
    if (where) where = where.data;
    if (where && Object.hasOwnProperty.call(where, key)) return where[key];
    return undefined;
  }

  return (this || _global).list[0][key];
};

ConfigChain.prototype.save = function (where, type, cb) {
  if (typeof type === "function") cb = type, type = null;
  var target = (this || _global).sources[where];

  if (!target || !(target.path || target.source) || !target.data) {
    // TODO: maybe save() to a url target could be a PUT or something?
    // would be easy to swap out with a reddis type thing, too
    return this.emit("error", new Error("bad save target: " + where));
  }

  if (target.source) {
    var pref = target.prefix || "";
    Object.keys(target.data).forEach(function (k) {
      target.source[pref + k] = target.data[k];
    });
    return this || _global;
  }

  var type = type || target.type;
  var data = target.data;

  if (target.type === "json") {
    data = JSON.stringify(data);
  } else {
    data = ini.stringify(data);
  }

  (this || _global)._saving++;
  fs.writeFile(target.path, data, "utf8", function (er) {
    (this || _global)._saving--;

    if (er) {
      if (cb) return cb(er);else return this.emit("error", er);
    }

    if ((this || _global)._saving === 0) {
      if (cb) cb();
      this.emit("save");
    }
  }.bind(this || _global));
  return this || _global;
};

ConfigChain.prototype.addFile = function (file, type, name) {
  name = name || file;
  var marker = {
    __source__: name
  };
  (this || _global).sources[name] = {
    path: file,
    type: type
  };
  this.push(marker);

  this._await();

  fs.readFile(file, "utf8", function (er, data) {
    if (er) this.emit("error", er);
    this.addString(data, file, type, marker);
  }.bind(this || _global));
  return this || _global;
};

ConfigChain.prototype.addEnv = function (prefix, env, name) {
  name = name || "env";
  var data = exports.env(prefix, env);
  (this || _global).sources[name] = {
    data: data,
    source: env,
    prefix: prefix
  };
  return this.add(data, name);
};

ConfigChain.prototype.addUrl = function (req, type, name) {
  this._await();

  var href = url.format(req);
  name = name || href;
  var marker = {
    __source__: name
  };
  (this || _global).sources[name] = {
    href: href,
    type: type
  };
  this.push(marker);
  http.request(req, function (res) {
    var c = [];
    var ct = res.headers["content-type"];

    if (!type) {
      type = ct.indexOf("json") !== -1 ? "json" : ct.indexOf("ini") !== -1 ? "ini" : href.match(/\.json$/) ? "json" : href.match(/\.ini$/) ? "ini" : null;
      marker.type = type;
    }

    res.on("data", c.push.bind(c)).on("end", function () {
      this.addString(Buffer.concat(c), href, type, marker);
    }.bind(this || _global)).on("error", (this || _global).emit.bind(this || _global, "error"));
  }.bind(this || _global)).on("error", (this || _global).emit.bind(this || _global, "error")).end();
  return this || _global;
};

ConfigChain.prototype.addString = function (data, file, type, marker) {
  data = this.parse(data, file, type);
  this.add(data, marker);
  return this || _global;
};

ConfigChain.prototype.add = function (data, marker) {
  if (marker && typeof marker === "object") {
    var i = (this || _global).list.indexOf(marker);

    if (i === -1) {
      return this.emit("error", new Error("bad marker"));
    }

    this.splice(i, 1, data);
    marker = marker.__source__;
    (this || _global).sources[marker] = (this || _global).sources[marker] || {};
    (this || _global).sources[marker].data = data; // we were waiting for this.  maybe emit 'load'

    this._resolve();
  } else {
    if (typeof marker === "string") {
      (this || _global).sources[marker] = (this || _global).sources[marker] || {};
      (this || _global).sources[marker].data = data;
    } // trigger the load event if nothing was already going to do so.


    this._await();

    this.push(data);
    process.nextTick((this || _global)._resolve.bind(this || _global));
  }

  return this || _global;
};

ConfigChain.prototype.parse = exports.parse;

ConfigChain.prototype._await = function () {
  (this || _global)._awaiting++;
};

ConfigChain.prototype._resolve = function () {
  (this || _global)._awaiting--;
  if ((this || _global)._awaiting === 0) this.emit("load", this || _global);
};

export default module.exports;
const _find = module.exports.find,
      _parse = module.exports.parse,
      _json = module.exports.json,
      _env = module.exports.env,
      _ConfigChain = module.exports.ConfigChain;
export { _find as find, _parse as parse, _json as json, _env as env, _ConfigChain as ConfigChain };